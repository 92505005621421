import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEOPage';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import {
  wrapper,
  wrapper1200,
  featuredWrapper,
  breakpoints,
  ContentStyles,
  ContentStylesExt,
} from '../../utils/style';
//import ContentWithFluidImages from '../../components/shared/ContentWithFluidImages';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import Auth from '../../components/Auth/Auth';
import RegistrationFormStatic from '../../components/Auth/Form/RegistrationFormStaticCastrated';

const FeaturedWrapper = styled('div')`
  ${featuredWrapper}
  position: relative;
  margin: 15px 0;
  .gatsby-image-wrapper {
    border-radius: 24px;
  }
  img {
    border-radius: 24px;
  }
  h1 {
    position: absolute;
    top: 50%;
    left: 30px;
    margin: -60px 0 0;
    max-width: 50%;
    font-size: 3rem;
    line-height: 1.1;
  }
  @media (max-width: ${breakpoints.md}) {
    .gatsby-image-wrapper,
    img {
      border-radius: 10px;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    h1 {
      font-size: 1.5rem;
      margin-top: -5px;
      left: 20px;
    }
  }
`;

const NoFeaturedWrapperReg = styled('div')`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  border-radius: 16px;
  padding: 20px 30px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.1) 0%,
    transparent 100%
  );

  .top-block {
    flex-basis: 40%;
  }
  .top-block-right {
    flex-basis: 58%;
  }
  .auth-container {
    height: 50px;
    .auth-wrapper {
      text-align: left;
      width: auto;
      margin: 10px 0;
    }
    .button {
      margin-left: 0;
    }
  }
  @media (max-width: ${breakpoints.lg}) {
    .top-block {
      flex-basis: 100%;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    padding: 20px 0;
    background: none;
  }
`;

const WrapperDesktop = styled('div')`
  ${wrapper1200}
`;

/*const ContentWrapper = styled('div')`
  ${wrapper}
  ${ContentStyles}
  ${ContentStylesExt}
  .content-casino {
    figure {
      flex-basis: 25%;
    }
    dl {
      flex-basis: 74%;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .content-casino {
      figure, dl {
        flex-basis: 100%;
      }
    }
  }
`;*/

const PageTemplate = ({ data, pageContext }) => {
  const currentPage = data.wordpressPage;
  const featuredMedia = currentPage.featured_media;
  const metaTitle =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_title
      ? currentPage.yoast_meta.yoast_wpseo_title
      : currentPage.title + '- ' + process.env.OWNER;
  const metaDesc =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_metadesc
      ? currentPage.yoast_meta.yoast_wpseo_metadesc
      : '';
  const media = data.allWordpressWpMedia.edges;
  const games = data.allWordpressFreespinGameslist.edges;
  const breadcrumbs = pageContext.breadcrumbs;
  const page_title = pageContext.page_title;
  const socialImage = currentPage.featured_media?.localFile?.publicURL;

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={currentPage.path}
        breadcrumbs={breadcrumbs}
        socialImage={socialImage}
      />
      <WrapperDesktop>
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title} />
        {featuredMedia &&
        featuredMedia.localFile &&
        featuredMedia.localFile.childImageSharp &&
        featuredMedia.localFile.childImageSharp.fluid ? (
          <div>
            <FeaturedWrapper>
              <Img
                fluid={featuredMedia.localFile.childImageSharp.fluid}
                alt={
                  currentPage.featured_media.alt_text
                    ? currentPage.featured_media.alt_text
                    : currentPage.title
                }
              />
              <h1 dangerouslySetInnerHTML={{ __html: page_title }} />
            </FeaturedWrapper>
            <NoFeaturedWrapperReg>
              <div className="top-block">
                <p>Have an account?</p>
                <div className="auth-container">
                  <Auth hideRegButton={true} />
                </div>
              </div>
              <div className="top-block top-block-right">
                <RegistrationFormStatic games={games} />
              </div>
            </NoFeaturedWrapperReg>
          </div>
        ) : (
          <NoFeaturedWrapperReg>
            <div className="top-block">
              <h1 dangerouslySetInnerHTML={{ __html: currentPage.title }} />
              <p>Mainkan game baru secara gratis.</p>
              <div className="auth-container">
                <Auth hideRegButton={true} />
              </div>
            </div>
            <div className="top-block top-block-right">
              <RegistrationFormStatic games={games} />
            </div>
          </NoFeaturedWrapperReg>
        )}

        {/*<ContentWrapper>
          {process.env.WP_REPLACE_CONTENT_IMAGES === '1' ?
            ContentWithFluidImages(currentPage.content, media) :
            <div dangerouslySetInnerHTML={{ __html: currentPage.content }}/>}
        </ContentWrapper>*/}
      </WrapperDesktop>
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query($id: String!, $contentImages: [Int]) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      content
      date(formatString: "MMMM DD, YYYY")
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        page_template
      }
      featured_media {
        alt_text
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allWordpressWpMedia(filter: { wordpress_id: { in: $contentImages } }) {
      edges {
        node {
          wordpress_id
          alt_text
          source_url
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1400) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
              }
            }
          }
        }
      }
    }
    allWordpressFreespinGameslist {
      edges {
        node {
          code
          name
          thumb {
            source_url
            alt_text
          }
        }
      }
    }
  }
`;
